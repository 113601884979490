import Vue from 'vue/dist/vue.js'
import VueToggle from './vue-toggle.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-toggle')) {
    document.querySelectorAll('.js-vue-toggle').forEach(function(domElement){
      new Vue({
        el: domElement,
        components: { VueToggle }
      })
    });
  }
};
