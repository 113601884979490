import Vue from 'vue/dist/vue.js'
import VueCart from './vue-cart.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-cart')) {
    document.querySelectorAll('.js-vue-cart').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueCart }
      })
    });
  }
};
