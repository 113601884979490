import Vue from 'vue/dist/vue.js'
import VueSorting from './vue-sorting.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-sorting')) {
    document.querySelectorAll('.js-vue-sorting').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueSorting }
      })
    });
  }
};
