import Vue from 'vue/dist/vue.js'
import VueCollapse from './lib/vue2-collapse'
Vue.use(VueCollapse);

export default function() {
  if(document.querySelectorAll('.js-vue-collapse')) {
    document.querySelectorAll('.js-vue-collapse').forEach(function(domElement){
      new Vue({
        el: domElement
      })
    });
  }
};
