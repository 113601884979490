<script>
  export default {
    props: ['initialState'],
    template: '#vue-format',

    data: function() {
      return {
        enabled: false
      }
    },

    methods: {
      setState: function() {
        let at_least_one = Array.from(this.formats).reduce(function(acc, format) {
          if(format.checked) {
            return true;
          } else {
            return acc;
          }
        }, false);
        this.enabled = at_least_one;
      }
    },

    mounted: function () {
      if(this.initialState !== undefined && (this.initialState == true || this.initialState == false)) {
        this.enabled = this.initialState;
      }

      this.formats = this.$el.querySelectorAll('.book-format__checkbox');
      if(this.formats !== null) {
        this.formats.forEach((format) => {
          format.addEventListener('click', () => {
            this.setState();
          });
        });
      }
    }
  }
</script>
