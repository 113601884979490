export default function(form) {
  const $form = $(form);

  // Visibility and requirement of text fields depending on option selected
  $form.find('.visibility-toggler').click(function(toggler) {
    $form.find('.visibility-toggled').hide();
    $form.find('.visibility-toggled input').removeAttr('required');
    $form.find('.visibility-toggled.' + $(toggler.currentTarget).data('toggle')).show();
    $form.find('.visibility-toggled.' + $(toggler.currentTarget).data('toggle') + ' input').attr('required', 'required');
  });
  $form.find('.visibility-toggled').hide();
  $form.find('.visibility-toggler:has(input:checked)').each(function(index, toggler) {
    $(toggler).click();
  });
};
