/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue/dist/vue.js'
import VueCart from '../components/vue-cart'
import VueToggle from '../components/vue-toggle'
import VueWishlist from '../components/vue-wishlist'
import VueCountry from '../components/vue-country'
import VueAccount from '../components/vue-account'
import VueCollapse from '../components/vue-collapse'
import VueFormat from '../components/vue-format'
import VueSorting from '../components/vue-sorting'
import VueAudioPlayer from '../components/vue-audio-player'
import VueSuggestion from '../components/vue-suggestion'
import CssVars from 'css-vars-ponyfill'

import VueClickaway from 'vue-clickaway'
Vue.mixin(VueClickaway.mixin)

import ToggleBodyOverflow from '../scripts/toggle-body-overflow';
import MemberNumber from '../scripts/member-number';
import Messages from '../scripts/messages';
import LegalInvoice from '../scripts/legal-invoice';

window.app = {};
app.init = () => {
  CssVars({});

  document.querySelectorAll('.js-toggle-body-overflow').forEach(function(input) {
    new ToggleBodyOverflow(input);
  });
  document.querySelectorAll('.js-member-number').forEach(function(form) {
    new MemberNumber(form);
  });
  document.querySelectorAll('.js-legal-invoice-form').forEach(function(form) {
    new LegalInvoice(form);
  });
  new Messages();

  VueCart();
  VueToggle();
  VueWishlist();
  VueCountry();
  VueCollapse();
  VueSorting();
  VueAccount();
  VueAudioPlayer();
  VueSuggestion();
}

$(document).on('ready', app.init)
