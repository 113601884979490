import Vue from 'vue/dist/vue.js'
import VueWishlist from './vue-wishlist.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-wishlist')) {
    document.querySelectorAll('.js-vue-wishlist').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueWishlist }
      })
    });
  }
};
