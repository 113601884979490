import Vue from 'vue/dist/vue.js'
import VueSuggestion from './vue-suggestion.vue';

export default function() {
  if(document.querySelectorAll('.js-vue-suggestion')) {
    document.querySelectorAll('.js-vue-suggestion').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueSuggestion }
      })
    });
  }
};
