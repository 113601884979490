<template>
  <form class="form suggest" :action="search_url" accept-charset="UTF-8" method="get" v-on-clickaway="away">
    <input name="utf8" type="hidden" value="✓">
    <div class="form__control form__control--search">
      <input
        type="text"
        name="keywords"
        id="keywords"
        :placeholder="placeholder"
        :title="placeholder"
        class="form__element"
        v-model="internal_value"
        @input="suggest"
        autocomplete="off"
        @focus="showOptions">
      <button class="form__element" type="submit" :value="button_text">
        <svg class="icon icon--search" focusable="false"><use xlink:href="#search"></use></svg>
      </button>
      <ul class="suggest--choices" v-show="visible">
        <li v-for="option in internal_options" v-bind:key="option.id" class="suggest--choice">
          <a :href="option.url" class="suggest--link">
            <img :src="option.cover" class="book__cover" onerror="imgError(this)" />
            <div class="suggest--product-info">
              <div class="suggest--title" v-html="option.label"></div>
              <div class="suggest--details">
                <div class="suggest--author" v-html="option.author"></div>
                <div class="suggest--price">{{option.price}}</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </form>
</template>

<script>
  import Vue from 'vue/dist/vue.js';
  import {_} from 'vue-underscore';

  export default {
    props: ['search_url', 'suggest_url', 'value', 'placeholder', 'button_text'],

    data: function() {
      return {
        internal_value: this.value,
        internal_options: [],
        visible: false
      }
    },

    methods: {
      suggest: _.debounce(function() {
        this.loadSuggestions();
      }, 500),
      loadSuggestions: function() {
        if(this.internal_value.length < 2) {
          this.internal_options = [];
          this.visible = false;
          return;
        }

        fetch(this.suggest_url + '?term=' + this.internal_value,
              { credentials: 'include' })
          .then(res => res.json())
          .then(res => {
            this.internal_options = res;
            this.setOptionsVisibility();
          });
      },
      away: function() {
        this.visible = false;
      },
      showOptions: function() {
        if(this.internal_value.length >= 2 && this.internal_options.length == 0) {
          this.loadSuggestions();
        } else {
          this.setOptionsVisibility();
        }
      },
      setOptionsVisibility: function() {
        if(this.internal_options.length == 0) {
          this.visible = false;
        } else {
          this.visible = true;
        }
      }
    },

    computed: {
    }
  }
</script>
