import Vue from 'vue/dist/vue.js'
import VueAudioPlayer from './vue-audio-player.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-audio-player')) {
    document.querySelectorAll('.js-vue-audio-player').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueAudioPlayer }
      })
    });
  }
};
