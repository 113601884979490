import Vue from 'vue/dist/vue.js'
import VueAccount from './vue-account.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-account')) {
    document.querySelectorAll('.js-vue-account').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueAccount }
      })
    });
  }
};
