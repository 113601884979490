import Vue from 'vue/dist/vue.js'
import VueCountry from './vue-country.vue'

export default function() {
  if(document.querySelectorAll('.js-vue-country')) {
    document.querySelectorAll('.js-vue-country').forEach(function(domElement) {
      new Vue({
        el: domElement,
        components: { VueCountry }
      })
    });
  }
};
