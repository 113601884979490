<script>
  export default {
    props: ['initialState'],
    template: '#vue-wishlist',

    mounted: function(){
      if(this.initialState !== undefined && (this.initialState == true || this.initialState == false)){
        this.visible = this.initialState;
      }
    },

    data: function(){
      return {
        visible: false
      }
    },

    methods: {
      initialize: function() {},
      away: function() {
        this.visible = false;
      }
    }
  }
</script>
