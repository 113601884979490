export default function(form) {
  var emailInput = document.querySelector('#spree_user_email');
  var memberNumberSection = document.querySelector('.member-number');
  var memberNumberInput = document.querySelector('#number');
  emailInput.addEventListener('change', function() {
    if(this.value === '') {
      memberNumberSection.style.display = 'none';
      memberNumberInput.value = '';
    } else {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        console.log('readyState', this.readyState);
        console.log('status', this.status);
        console.log('responseText', this.responseText);
        if (this.readyState == 4 && this.status == 200) {
          if(this.responseText === 'true') {
            memberNumberSection.style.display = 'none';
            memberNumberInput.value = '';
          } else {
            memberNumberSection.style.display = 'block';
          }
        }
      };
      xhttp.open('GET', '/memberships?email=' + encodeURIComponent(this.value).replace(/\./g, '%2E'), true);
      xhttp.send();
    }
  });
};
