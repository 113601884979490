<template>
  <a
    class="audio-player__button button button--round"
    @click.prevent="toggle"
    href=""
    aria-pressed="false"
    :class="{ 'audio-player__button--card': card === 'true' }"
    role="button"
    v-bind:aria-label="title_start">
    <div class="audio-player__icon">
      <div class="audio-button__left"></div>
      <div class="audio-button__right"></div>
      <div class="audio-button__triangle-1"></div>
      <div class="audio-button__triangle-2"></div>
    </div>
    <audio :src="sampleUrl" @ended="toggle" @pause="checkToggle" @play="checkToggle"  preload="none"></audio>
  </a>
</template>

<script>
  import Vue from 'vue/dist/vue.js'

  export default {
    props: ["sampleUrl", "card", "title_start", "title_stop"],

    data: function() {
      return {
        audioEl: undefined,
        label: this.title_start
      }
    },

    mounted: function () {
      this.audioEl = this.$el.querySelectorAll('audio')[0];
    },

    methods: {
      toggle: function () {
        // the audio player state must be kept up to date for the button icon,
        // which is done through css via the aria-pressed attribute
        this.playing() ? this.pause() : this.play();
      },
      pause: function () {
        if (this.playing()) {
          this.setState("false");
          this.audioEl.pause();
          this.currentPlayer = false;
        }
      },
      play: function () {
        this.stopOtherAudioElements();
        this.setState("true");
        this.audioEl.play();
      },
      playing: function () {
        return !this.audioEl.paused;
      },
      stopOtherAudioElements: function () {
        Array.from(document.getElementsByClassName("audio-player__button")).forEach(function (el) {
          if (!el.children[1].paused) {
            el.children[1].pause();
            el.setAttribute("aria-pressed", "false");
          }
        });
      },
      checkToggle: function () {
        if (this.audioEl.paused && this.getState !== "false") {
          this.setState("false");
        } else if (this.playing() && this.getState !== "true") {
          this.setState("true");
        }
      },
      setState: function (state) {
        this.$el.setAttribute("aria-pressed", state);
      },
      getState: function () {
        return this.$el.getAttribute("aria-pressed");
      }
    }
  }
</script>
