export default function() {
  window.addEventListener('DOMContentLoaded', function() {
    window.parent.postMessage('cb.load', '*');
  });

  window.addEventListener('beforeunload', function() {
    window.parent.postMessage('cb.unload', '*');
  });

  document.querySelectorAll('.cb-close-popup').forEach(function(item) {
    item.addEventListener('click', function() {
      window.parent.postMessage('cb.close-popup', '*');
    })
  });

  document.querySelectorAll('.cb-refresh-parent').forEach(function(item) {
    item.addEventListener('click', function() {
      window.parent.postMessage('cb.refresh-parent', '*');
    })
  });
};

window.orderCompleted = function() {
  window.parent.postMessage('cb.order-completed', '*');
};
